<template>
  <div>
    <card
      class="card"
      title="Fazer inventário da máquina"
      v-if="selectedMachine"
    >
      <!-- Modal Add Product -->
      <div
        class="modal fade"
        id="modalAddProduct"
        tabindex="-1"
        aria-labelledby="modalAddProductLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalAddProductLabel">
                Adicionar produto no estoque
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="picture-container mx-auto">
                <ProductImage :size="75" :src="selectedProduct.image" />
              </div>
              <ValidationObserver
                ref="modalAddProduct"
                v-slot="{ handleSubmit }"
              >
                <form
                  class="row g-3 justify-content-center"
                  @submit.prevent="
                    handleSubmit(() => onSubmitAddProduct('modalAddProduct'))
                  "
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider
                          name="Produto"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label
                            for="inputProductSelect"
                            class="form-label text-dark"
                            >Produto</label
                          >
                          <multiselect
                            :max-height="150"
                            :maxSelectedLabels="1"
                            id="inputProductSelect"
                            :showLabels="false"
                            v-model="selectedProduct"
                            :options="products"
                            placeholder="Nome do produto"
                            label="title"
                            track-by="title"
                          >
                            <template #option="slotProps">
                              <div class="flex align-items-center text-dark">
                                <img
                                  :alt="slotProps.option.title"
                                  :src="slotProps.option.image"
                                  class="mr-2"
                                  style="width: 18px"
                                />
                                <span>{{ slotProps.option.title }}</span>
                              </div>
                            </template>
                          </multiselect>
                          <p class="fw-normal text-danger p-relative">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider
                          name="Disponibilidade"
                          rules="required|min_value:1"
                          v-slot="{ errors }"
                        >
                          <label
                            for="inputAvailability"
                            class="form-label text-dark"
                            >Quantidade que deseja adicionar</label
                          >
                          <input
                            type="number"
                            min="0"
                            v-model="selectedProduct.quantity"
                            class="form-control text-dark"
                            id="inputAvailability"
                          />
                          <p class="fw-normal text-danger p-relative">
                            {{ errors[0] }}
                          </p>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="row row py-4">
                      <div class="col-12 text-right">
                        <button
                          type="button"
                          class="btn btn-secondary mr-3"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button
                          type="submit"
                          :disabled="loading"
                          class="btn btn-primary"
                        >
                          Adicionar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <!-- End Add Product -->

      <LoadingSpinner :show="loading" />
      <div class="card-body">
        <div class="row justify-content-between mb-5">
          <div>
            <button class="btn btn-warning mr-4" @click="chooseMachine">
              Escolher máquina
            </button>
            <button
              class="btn btn-secondary"
              data-toggle="modal"
              data-target="#modalAddProduct"
            >
              Adicionar produto
            </button>
          </div>
          <a ref="csvDownloadLink" v-show="false" class="btn btn-info"
            >Download Excel</a
          >
          <button @click="onSubmitInventory" class="btn btn-success">
            Salvar e gerar relatório
          </button>
        </div>
        <StockInventoryPDF
          name="INVENTÁRIO_MÁQUINA"
          ref="stockInventoryPDF"
          :stockInventory="machineProducts"
          :user="{ name: manager.name, email: manager.email }"
        />
        <table class="table table-responsive-xl text-center">
          <thead>
            <tr>
              <th scope="col">Imagem do Produto</th>
              <th scope="col">Quant Estoque</th>
              <th class="text-left" scope="col">Produto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) of machineProducts" :key="index">
              <td>
                <ProductImage :size="55" :src="product.image" />
              </td>
              <td>
                <div class="container-quantity">
                  <button
                    class="quantity-items btn btn-danger px-3 py-2"
                    @click="handleQuantityProduct(product, 'remove')"
                  >
                    -
                  </button>
                  <input
                    class="mx-2 font-weight-bold input-quantity"
                    type="number"
                    step="1"
                    min="0"
                    v-model="product.quantity"
                  />
                  <button
                    class="quantity-items btn btn-success px-3 py-2"
                    @click="handleQuantityProduct(product, 'add')"
                  >
                    +
                  </button>
                </div>
              </td>
              <td>
                <p class="text-left text-white mx-2 font-weight-bold">
                  {{ product.title }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card>
    <div v-else>
      <SelectMachineInventory @selected-machine="receiveEmitMachineSelected" />
    </div>
  </div>
</template>
<script>
import CSVReport from "../../services/CSVReport";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ProductImage from "../../components/ProductImage.vue";
import LoadingSpinner from "../../components/Spinner.vue";
import SelectMachineInventory from "../../components/SelectMachineInventory.vue";
import StockInventoryPDF from "../../components/StockInventoryPDF/StockInventoryPDF.vue";
import {
  minValue,
  positive,
  validationRequire,
} from "../../middleware/validations";

StockInventoryPDF;
moment.locale("pt-BR");
validationRequire();
minValue();
positive();

export default {
  name: "CreateMachineInventory",
  components: {
    LoadingSpinner,
    ProductImage,
    ValidationObserver,
    ValidationProvider,
    Multiselect,
    StockInventoryPDF,
    SelectMachineInventory,
  },
  data() {
    return {
      loading: false,
      isRefreshing: false,
      warehouse_id: "",
      company_id: "",
      selectedProduct: {},
      machineProducts: [],
      products: [],
      manager: null,
      selectedMachine: null,
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.manager = this.$store.getters["manager/getManager"];
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    const initialLoading = async () => {
      await this.getMachineProducts();
    };
    initialLoading();
  },
  methods: {
    async onSubmitAddProduct(modalId) {
      const alreadyHasProduct = this.machineProducts.some(
        (product) => product.product_id == this.selectedProduct.product_id
      );
      const { product_id, quantity, image, title } = this.selectedProduct;
      if (alreadyHasProduct) {
        this.$toast.info(`${this.selectedProduct.title} já existe na máquina`);
        this.selectedProduct = {
          product_id: "",
          quantity: 0,
        };
        return;
      }
      const addProduct = {
        product_id,
        image,
        quantity: Number(quantity),
        title,
      };
      this.machineProducts.push(addProduct);
      this.$refs.modalAddProduct.reset();
      $(`#${modalId}`).modal("hide");
      this.$toast.success(`${this.selectedProduct.title} adicionado`);
      this.selectedProduct = {};
    },
    async onSubmitInventory() {
      const items = this.machineProducts.map((item) => {
        const { product_id, quantity } = item;
        return {
          product_id,
          quantity: Number(quantity),
        };
      });
      const response = await this.createMachineInventory(items);
      const { inventory } = response.data;
      this.$refs.stockInventoryPDF.$refs.html2Pdf.generatePdf();
      this.CSVReportInventory();
      this.$router.push({
        name: "machineInventory",
        params: { id: inventory.inventory_id },
      });
    },
    async createMachineInventory(items) {
      try {
        this.loading = true;
        if (!this.selectedMachine) {
          this.$toast.warning("Selecione uma máquina");
          return;
        }
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          machine_id: this.selectedMachine,
          profile_id: this.manager.manager_id,
          products: items,
        };
        const response = await this.$http.post("machine/inventory", params);
        this.$toast.success("Inventário feito com sucesso");
        this.loading = false;
        return response;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getMachineProducts() {
      try {
        this.loading = true;
        if (!this.selectedMachine) {
          this.loading = false;
          return;
        }
        const params = {
          machine_id: this.selectedMachine,
          company_id: this.company_id,
        };
        const { data } = await this.$http.get("/machine/products", {
          params,
        });
        const { machineProducts } = data;
        machineProducts.forEach((product) => {
          if (product.amount <= 0) {
            this.products.push(product);
          }
        });
        this.machineProducts = machineProducts
          .filter((product) => product.amount > 0)
          .map((product) => {
            return {
              product_id: product.product_id,
              title: product.title,
              image: product.image,
              quantity: 0,
            };
          });
        this.loading = false;
      } catch (err) {
        this.isRefreshing = false;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    CSVReportInventory() {
      const csvReport = new CSVReport(
        this.machineProducts,
        "INVENTÁRIO_MÁQUINA"
      );
      this.$refs.csvDownloadLink.href = csvReport.execute();
      this.$refs.csvDownloadLink.download = csvReport.name;
      this.$refs.csvDownloadLink.click();
    },
    handleQuantityProduct(item, operation) {
      this.machineProducts = this.machineProducts.map((product) => {
        if (product.product_id == item.product_id) {
          if (operation == "add") {
            product.quantity = Number(product.quantity) + 1;
          } else {
            if (!product.quantity == 0)
              product.quantity = Number(product.quantity) - 1;
          }
        }
        return product;
      });
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    receiveEmitMachineSelected(data) {
      this.selectedMachine = data.machine_id;
    },
    chooseMachine() {
      this.selectedMachine = null;
    },
  },
  watch: {
    selectedMachine() {
      this.getMachineProducts();
    },
    selectedProduct() {
      if (this.selectedProduct == null) {
        this.selectedProduct = {};
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.container-quantity .input-quantity {
  font-size: 1.2rem;
  background: none;
  text-align: center;
  color: white;
  border: none;
  width: 80px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 12px;
  appearance: textfield;
  padding: 8px 0;
  -moz-appearance: textfield;
}

.input-quantity:focus {
  outline: none;
}

.container-quantity .quantity-items {
  font-size: 1.2rem;
  cursor: pointer;
}
</style>
