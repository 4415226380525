<template>
  <div>
    <card class="p-3" title="Movimentações">
      <LoadingSpinner :show="loading" />
      <div class="row align-center justify-content-between mb-4">
        <div class="col-3">
          <label for="inputMachine" class="form-label text-light"
            >Filtrar por máquina</label
          >
          <select
            v-model="selectedMachine"
            class="form-control"
            id="inputMachine"
            @change="getMetrics()"
          >
            <option class="bg-dark" :selected="!selectedMachine" :value="null">
              --- Todas as máquinas ---
            </option>
            <option
              v-for="machine in machines"
              :value="machine.machine_id"
              :key="machine.machine_id"
              class="bg-dark"
            >
              {{ machine.description }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <label for="inputProduct" class="form-label text-light"
            >Filtrar por produto</label
          >
          <select
            v-model="selectedProduct"
            class="form-control"
            id="inputProduct"
          >
            <option class="bg-dark" :selected="!selectedProduct" :value="null">
              --- Todas os produtos ---
            </option>
            <option
              v-for="(product, idx) in getProducts"
              :value="product.product_id"
              :key="idx"
              class="bg-dark"
            >
              {{ product.name }}
            </option>
          </select>
        </div>

        <div class="col-6 text-right">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <div
              key="startDate"
              for="startDate"
              name="startDate"
              class="datepicker-container btn btn-sm btn-primary btn-simple"
            >
              <label
                for="startDate"
                class="btn btn-sm btn-primary btn-simple startDate"
                >Data Inicial</label
              >
              <input
                class="text-primary date-picker"
                type="date"
                v-model="startDate"
                name="startDate"
                id="startDate"
                @change="handleDateChanges()"
              />
            </div>
            <div
              key="endDate"
              for="endDate"
              name="endDate"
              class="datepicker-container btn btn-sm btn-primary btn-simple"
            >
              <label
                for="endDate"
                class="btn btn-sm btn-primary btn-simple endDate"
                >Data Final</label
              >
              <input
                class="text-primary date-picker"
                type="date"
                v-model="endDate"
                name="endDate"
                id="endDate"
                @change="handleDateChanges()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <StockMetricsTable title="Relatório de Vendas" :logList="salesReport" />
        <h3 class="text-right">Valor total {{ toBRL(total / 100) }}</h3>
      </div>
    </card>
  </div>
</template>
<script>
import moment from "moment";
import LoadingSpinner from "../components/Spinner.vue";
import StockMetricsTable from "../components/StockMetrics/StockMetricsTable.vue";
import TabNavigation from "../components/TabNavigation.vue";
moment.locale("pt-BR");
moment().format();

export default {
  name: "StockMetrics",
  components: {
    LoadingSpinner,
    TabNavigation,
    StockMetricsTable,
  },
  data() {
    return {
      total: 0,
      startDate: "",
      endDate: "",
      selectedMachine: "",
      selectedProduct: "",
      loading: false,
      machineStockLog: [],
      metrics: [],
      machines: [],
      products: [],
      warehouse_id: "",
      company_id: "",
    };
  },
  async mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.startDate = moment().weekday(1).format("YYYY-MM-DD");
    this.endDate = moment().weekday(6).format("YYYY-MM-DD");
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getMetrics();
    await this.getMachines();
  },
  methods: {
    resizeChartData(data, size = 10) {
      const newChartData = [];
      while (data.categories.length) {
        newChartData.push({
          categories: data.categories.splice(0, size),
          series: [
            { name: "Quantidade", data: data.series[0].data.splice(0, size) },
          ],
          unitPrices: { data: data.unitPrices.data.splice(0, size) },
        });
      }
      return newChartData;
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    async handleDateChanges() {
      await this.getMetrics();
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id: this.company_id,
          },
        });

        this.machines = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getMetrics() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.selectedMachine,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("stock/metrics", {
          params,
        });
        const { metrics } = response.data;
        this.metrics = metrics;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    filteredSalesReport() {
      const metricsList = this.metrics;
      if (!this.selectedProduct) return metricsList;
      return metricsList.filter((metric) => {
        return metric.product_id == this.selectedProduct;
      });
    },
  },
  computed: {
    getProducts() {
      const items = new Map();
      this.metrics.forEach((metric) => {
        items.set(metric.product_id, {
          product_id: metric.product_id,
          name: metric.title,
        });
      });
      return Array.from(items.values());
    },
    getChartData() {
      const salesItems = [];
      this.salesReport.forEach((metric) => {
        const getData = salesItems.find((item) => item.title == metric.title);
        if (getData) {
          getData.quantity += sale.quantity;
          getData.price = sale.price;
          getData.acquisition_price = sale.acquisition_price;
        } else {
          salesItems.push({
            name: sale.product.name,
            quantity: sale.quantity,
            price: sale.price,
            acquisition_price: sale.acquisition_price,
          });
        }
      });
      salesItems.sort((itemA, itemB) => itemB.quantity - itemA.quantity);
      const chartData = {
        categories: [],
        series: [{ name: "Quantidade", data: [] }],
        unitPrices: {
          data: [],
        },
      };
      for (let product of salesItems) {
        const { quantity, price } = product;
        const [productFirstName, productLastName] = product.name.split(" ");
        let name = `${this.toBRL(price / 100)} | ${productFirstName}`;
        if (productLastName) {
          name += ` ${productLastName}`;
        }
        chartData.categories.push(`${name}`);
        chartData.series[0].data.push(quantity);
        chartData.unitPrices.data.push(price);
      }
      return [chartData];
    },
    salesReport() {
      let total = 0;
      this.metrics.forEach((sale) => {
        total += (sale.price - sale.discount) * sale.quantity;
      });
      this.total = total;
      console.log(this.filteredSalesReport());
      return this.filteredSalesReport();
    },
  },
};
</script>
<style lang="css" scoped>
</style>
