<template>
  <card class="p-4" title="Estoque de Produtos">
    <!-- Modal Create Batch Product -->
    <LoadingSpinner :show="loading" />
    <div
      class="modal fade"
      id="modalCreateBatchProduct"
      tabindex="-1"
      aria-labelledby="modalCreateBatchProductLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCreateBatchProductLabel">
              Adicionar produto no estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ProductImage :size="75" :src="selectedBatchProduct.image" />
            <ValidationObserver
              ref="modalCreateBatchProduct"
              v-slot="{ handleSubmit }"
            >
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="
                  handleSubmit(() =>
                    onSubmitCreateBatchProduct('modalCreateBatchProduct')
                  )
                "
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Produto"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputProductSelect"
                          class="form-label text-dark"
                          >Produto</label
                        >
                        <multiselect
                          :max-height="150"
                          :maxSelectedLabels="1"
                          id="inputProductSelect"
                          :showLabels="false"
                          v-model="selectedBatchProduct"
                          :options="products"
                          placeholder="Nome do produto"
                          label="title"
                          track-by="title"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center text-dark">
                              <img
                                :alt="slotProps.option.title"
                                :src="slotProps.option.image"
                                class="mr-2"
                                style="width: 18px"
                              />
                              <span>{{ slotProps.option.title }}</span>
                            </div>
                          </template>
                        </multiselect>
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <label
                        for="inputAcquisitionPrice"
                        class="form-label text-dark"
                        >Preço de aquisição do produto
                        <span class="text-danger">(*)</span></label
                      >
                      <money
                        :disabled="hasAcquisitionPrice"
                        class="form-control text-dark"
                        id="inputAcquisitionPrice"
                        v-model="batchProduct.acquisition_price"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Data expiração"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputDateExpiration"
                          class="form-label text-dark"
                          >Data de Validade do Lote</label
                        >
                        <input
                          type="date"
                          v-model="batchProduct.expiration"
                          class="form-control text-dark"
                          id="inputDateExpiration"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Unidade Comercial"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputProductMoviment"
                          class="form-label text-dark"
                          >Tipo Movimentação</label
                        >
                        <select
                          v-model="batchProduct.action"
                          class="form-control text-dark"
                          id="inputProductMoviment"
                        >
                          <option
                            v-for="action in addActions"
                            :value="action.value"
                            :key="action.value"
                          >
                            {{ action.label }}
                          </option>
                        </select>
                        <span class="fw-normal text-danger">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Disponibilidade"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputAvailability"
                          class="form-label text-dark"
                          >Quantidade que deseja adicionar</label
                        >
                        <input
                          type="number"
                          min="0"
                          v-model="batchProduct.amount"
                          class="form-control text-dark"
                          id="inputAvailability"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Create Batch Product -->

    <!-- Modal Add Product -->
    <div
      class="modal fade"
      id="modalAddProduct"
      tabindex="-1"
      aria-labelledby="modalAddProductLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddProductLabel">
              Adicionar produto no estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductImage :size="75" :src="productSelected.image" />
            <ValidationObserver ref="modalAddProduct" v-slot="{ handleSubmit }">
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="
                  handleSubmit(() => onSubmitAddProduct('modalAddProduct'))
                "
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Unidade Comercial"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputProductMoviment"
                          class="form-label text-dark"
                          >Tipo Movimentação</label
                        >
                        <select
                          v-model="productSelected.action"
                          class="form-control text-dark"
                          id="inputProductMoviment"
                        >
                          <option
                            v-for="action in addActions"
                            :value="action.value"
                            :key="action.value"
                          >
                            {{ action.label }}
                          </option>
                        </select>
                        <span class="fw-normal text-danger">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Disponibilidade"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputAvailability"
                          class="form-label text-dark"
                          >Quantidade que deseja adicionar</label
                        >
                        <input
                          type="number"
                          min="0"
                          v-model="productSelected.amount"
                          class="form-control text-dark"
                          id="inputAvailability"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Add Product -->

    <!-- Modal Remove Product -->
    <div
      class="modal fade"
      id="modalRemoveProduct"
      tabindex="-1"
      aria-labelledby="modalRemoveProductLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalRemoveProductLabel">
              Remover produto no estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ProductImage :size="75" :src="productSelected.image" />
            <ValidationObserver
              ref="modalRemoveProduct"
              v-slot="{ handleSubmit }"
            >
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="
                  handleSubmit(() =>
                    onSubmitRemoveProduct('modalRemoveProduct')
                  )
                "
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Unidade Comercial"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputProductMoviment"
                          class="form-label text-dark"
                          >Tipo Movimentação</label
                        >
                        <select
                          v-model="productSelected.action"
                          class="form-control text-dark"
                          id="inputProductMoviment"
                        >
                          <option
                            v-for="action in removeActions"
                            :value="action.value"
                            :key="action.value"
                          >
                            {{ action.label }}
                          </option>
                        </select>
                        <span class="fw-normal text-danger">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Disponibilidade"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputAvailability"
                          class="form-label text-dark"
                          >Quantidade que deseja remover</label
                        >
                        <input
                          type="number"
                          min="0"
                          v-model="productSelected.amount"
                          class="form-control text-dark"
                          id="inputAvailability"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Remove Product -->

    <!-- Modal Update Product -->
    <div
      class="modal fade"
      id="modalUpdateProduct"
      tabindex="-1"
      aria-labelledby="modalUpdateProductLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUpdateProductLabel">
              Adicionar produto no estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ProductImage :size="75" :src="updateProductSelected.image" />
            <ValidationObserver
              ref="modalUpdateProduct"
              v-slot="{ handleSubmit }"
            >
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="
                  handleSubmit(() =>
                    onSubmitUpdateProduct('modalUpdateProduct')
                  )
                "
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <label
                        for="inputAcquisitionPrice"
                        class="form-label text-dark"
                        >Preço de aquisição do produto
                        <span class="text-danger">(*)</span></label
                      >
                      <money
                        class="form-control text-dark"
                        id="inputAcquisitionPrice"
                        v-model="updateProductSelected.acquisition_price"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Update Product -->

    <div class="row my-2 justify-content-between align-items-center">
      <div class="col-12 col-md-6 text-left">
        <label>Procurar produtos</label>
        <base-input
          v-model="search"
          addonLeftIcon="tim-icons icon-zoom-split"
          placeholder="Ex:. Coca cola"
        ></base-input>
      </div>
      <div class="col-12 col-md-6 text-right">
        <button
          data-toggle="modal"
          data-target="#modalCreateBatchProduct"
          class="btn btn-success"
        >
          Adicionar produto
        </button>
      </div>
    </div>

    <TabNavigation
      :mode="amountZeroStockProducts"
      :labels="{ first: 'Estoque', second: 'Lotes zerados' }"
      @toggle-mode="receiveEmitToggleMode"
    />
    <div
      class="text-right cursor-pointer d-inline-block ml-auto mr-2 my-3"
      :class="{ 'transition-animation': isRefreshing }"
      @click="refreshProductsStock()"
    >
      <unicon name="sync" fill="#00f2c3" width="16px"></unicon>
    </div>
    <div
      class="card"
      v-for="(product, index) of amountZeroStockProducts
        ? filteredZeroAmountProducts
        : filteredStockProducts"
      :key="product.product_id"
    >
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Produto</th>
            <th scope="col">Descrição</th>
            <th scope="col">Data Validade</th>
            <th scope="col">Quant Estoque</th>
            <th scope="col">Valor de Aquisição</th>
            <th scope="col">Adicionar</th>
            <th scope="col">Remover</th>
            <th scope="col">Editar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ProductImage :size="60" :src="product.image" />
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.title }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.description }}
              </p>
            </td>
            <td>
              <p
                class="personalized-badge text-dark mx-2"
                :class="isValidDate(product.stock[0].expiration)"
              >
                {{ dateFormatted(product.stock[0].expiration) }}
              </p>
            </td>
            <td>
              <p class="personalized-badge badge-info text-white mx-2">
                {{ product.stock[0].amount }}
              </p>
            </td>
            <td>
              <p class="personalized-badge badge-default text-white mx-2">
                {{ toBRL(product.stock[0].acquisition_price) }}
              </p>
            </td>
            <td
              data-toggle="modal"
              data-target="#modalAddProduct"
              @click="
                productSelected = {
                  product_id: product.product_id,
                  image: product.image,
                  action: 1,
                  amount: 0,
                  expiration: product.stock[0].expiration,
                }
              "
            >
              <unicon name="plus" fill="#00f2c3" width="22px"></unicon>
            </td>
            <td
              data-toggle="modal"
              data-target="#modalRemoveProduct"
              @click="
                productSelected = {
                  product_id: product.product_id,
                  image: product.image,
                  action: 7,
                  amount: 0,
                  expiration: product.stock[0].expiration,
                }
              "
            >
              <unicon name="minus" fill="#fd5d93" width="22px"></unicon>
            </td>
            <td
              data-toggle="modal"
              data-target="#modalUpdateProduct"
              @click="
                updateProductSelected = {
                  image: product.image,
                  product_id: product.stock[0].product_id,
                  expiration: product.stock[0].expiration,
                  acquisition_price: product.stock[0].acquisition_price / 100,
                }
              "
            >
              <p>
                <unicon name="edit" fill="#1d8cf8" width="22px"></unicon>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="collapse" :id="'collapse' + product.product_id">
        <table class="table table-responsive-xl text-center">
          <thead v-show="index <= 0" style="opacity: 0">
            <tr>
              <th scope="col">Imagem do Produto</th>
              <th scope="col">Produto</th>
              <th scope="col">Descrição</th>
              <th scope="col">Data Validade</th>
              <th scope="col">Quant Estoque</th>
              <th scope="col">Valor de Aquisição</th>
              <th scope="col">Adicionar</th>
              <th scope="col">Remover</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-show="idx >= 1"
              v-for="(productBatch, idx) of product.stock"
              :key="`${idx}-${productBatch.expiration}`"
            >
              <td>
                <ProductImage :size="60" :src="product.image" />
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ product.title }}
                </p>
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ product.description }}
                </p>
              </td>
              <td>
                <p
                  class="personalized-badge text-dark mx-2"
                  :class="isValidDate(productBatch.expiration)"
                >
                  {{ dateFormatted(productBatch.expiration) }}
                </p>
              </td>
              <td>
                <p class="personalized-badge badge-info text-white mx-2">
                  {{ productBatch.amount }}
                </p>
              </td>
              <td>
                <p class="personalized-badge badge-default text-white mx-2">
                  {{ toBRL(productBatch.acquisition_price) }}
                </p>
              </td>
              <td
                data-toggle="modal"
                data-target="#modalAddProduct"
                @click="
                  productSelected = {
                    ...productSelected,
                    product_id: productBatch.product_id,
                    action: 1,
                    image: product.image,
                    expiration: productBatch.expiration,
                  }
                "
              >
                <unicon name="plus" fill="#00f2c3" width="22px"></unicon>
              </td>
              <td
                data-toggle="modal"
                data-target="#modalRemoveProduct"
                @click="
                  productSelected = {
                    ...productSelected,
                    product_id: productBatch.product_id,
                    action: 7,
                    image: product.image,
                    expiration: productBatch.expiration,
                  }
                "
              >
                <unicon name="minus" fill="#fd5d93" width="22px"></unicon>
              </td>
              <td
                data-toggle="modal"
                data-target="#modalUpdateProduct"
                @click="
                  updateProductSelected = {
                    image: product.image,
                    product_id: productBatch.product_id,
                    expiration: productBatch.expiration,
                    acquisition_price: productBatch.acquisition_price / 100,
                  }
                "
              >
                <p>
                  <unicon name="edit" fill="#1d8cf8" width="22px"></unicon>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        v-show="product.stock.length >= 2"
        class="text-center font-weight-medium details"
        data-toggle="collapse"
        :data-target="'#collapse' + product.product_id"
        aria-expanded="false"
        :aria-controls="'#collapse' + product.product_id"
      >
        Ver detalhamento
      </p>
    </div>
  </card>
</template>
<script>
import moment from "moment";
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ProductImage from "../components/ProductImage.vue";
import LoadingSpinner from "../components/Spinner.vue";
import TabNavigation from "../components/TabNavigation.vue";
import { positive, validationRequire } from "../middleware/validations";
import NotificationTemplate from "./Notifications/NotificationTemplate";
validationRequire();
positive();

export default {
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    Money,
    TabNavigation,
    ProductImage,
  },
  data() {
    return {
      loading: false,
      isRefreshing: false,
      warehouse_id: "",
      company_id: "",
      search: "",
      products: [],
      stockProducts: [],
      selectedBatchProduct: {},
      amountZeroStockProducts: false,
      hasAcquisitionPrice: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      addActions: [
        { label: "ENTRADA NF", value: 1 },
        { label: "ENTRADA DEVOLUCAO VENDA", value: 2 },
        { label: "ENTRADA DE PRODUCAO", value: 3 },
        { label: "ENTRADA BONIFICACAO", value: 4 },
        { label: "ENTRADA DOACAO", value: 5 },
        { label: "ENTRADA OUTRAS", value: 6 },
      ],
      removeActions: [
        { label: "SAIDA PERDA AVARIA", value: 7 },
        { label: "SAIDA PERDA VENCIMENTO", value: 8 },
        { label: "SAIDA PERDA ROUBO", value: 9 },
        { label: "SAIDA OUTRAS PERDAS", value: 10 },
        { label: "SAIDA DEVOLUCAO FORNECEDOR", value: 11 },
        { label: "SAIDA PARA MAQUINA", value: 12 },
        { label: "SAIDA OUTRAS", value: 13 },
      ],
      batchProduct: {
        expiration: "",
        amount: 0,
        acquisition_price: 0,
        action: 1,
      },
      productSelected: {
        product_id: "",
        image: "",
        action: 1,
        amount: 0,
        expiration: "",
      },
      updateProductSelected: {
        image: "",
        product_id: "",
        expiration: "",
        acquisition_price: 0,
      },
    };
  },
  async mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getProducts();
    await this.getProductsStock();
  },
  methods: {
    handleCreateInventory() {
      this.$router.push({ name: "createStockInventory" });
    },
    receiveEmitToggleMode(mode) {
      this.amountZeroStockProducts = mode;
    },
    async getProducts() {
      try {
        this.loading = true;
        const params = {
          warehouse_id: this.warehouse_id,
          company_id: this.company_id,
        };
        const { data } = await this.$http.get("/products", {
          params,
        });
        this.products = data.products;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    dateFormatted(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    cleanUpdateProductSelected() {
      this.updateProductSelected.image = "";
      this.updateProductSelected.product_id = "";
      this.updateProductSelected.expiration = "";
      this.updateProductSelected.acquisition_price = 0;
    },
    cleanAddBatchProductModal() {
      this.selectedBatchProduct = {};
      this.hasAcquisitionPrice = false;
      this.batchProduct.expiration = "";
      this.batchProduct.amount = 0;
      this.batchProduct.acquisition_price = 0;
      this.batchProduct.action = 1;
    },
    cleanProductSelectedModal() {
      this.productSelected = {
        product_id: "",
        image: "",
        action: 1,
        amount: 0,
        expiration: "",
      };
    },
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    notifyVue(verticalAlign, horizontalAlign, message, type) {
      this.$notify({
        component: NotificationTemplate,
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type,
        timeout: 0,
      });
    },
    async refreshProductsStock() {
      this.isRefreshing = true;
      await this.getProductsStock();
    },
    async getProductsStock() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        const { data } = await this.$http.get("/products/stock", {
          params,
        });
        const stockProducts = data.products;
        this.stockProducts = stockProducts;
        localStorage.setItem(
          "@Snackly:productsStock",
          JSON.stringify(stockProducts)
        );
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitCreateBatchProduct(modalId) {
      try {
        this.loading = true;
        const params = {
          expiration: this.batchProduct.expiration,
          action: this.batchProduct.action,
          amount: Number(this.batchProduct.amount),
          acquisition_price: Math.round(
            Number(this.batchProduct.acquisition_price * 100)
          ),
          product_id: this.selectedBatchProduct.product_id,
          warehouse_id: this.warehouse_id,
        };
        await this.$http.post("product/stock/create", params);
        this.$refs.modalCreateBatchProduct.reset();
        await this.getProductsStock();
        this.modalState(modalId, "hide");
        this.cleanAddBatchProductModal();
        this.$toast.success("Operação realizada com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitAddProduct(modalId) {
      try {
        this.loading = true;
        const params = {
          product_id: this.productSelected.product_id,
          action: this.productSelected.action,
          amount: Number(this.productSelected.amount),
          expiration: this.productSelected.expiration,
          warehouse_id: this.warehouse_id,
        };
        await this.$http.post("product/stock/add", params);
        this.$refs.modalAddProduct.reset();
        await this.getProductsStock();
        this.modalState(modalId, "hide");
        this.cleanProductSelectedModal();
        this.$toast.success("Operação realizada com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitRemoveProduct(modalId) {
      try {
        this.loading = true;
        const params = {
          product_id: this.productSelected.product_id,
          action: this.productSelected.action,
          amount: Number(this.productSelected.amount),
          expiration: this.productSelected.expiration,
          warehouse_id: this.warehouse_id,
        };
        await this.$http.post("product/stock/remove", params);
        this.$refs.modalRemoveProduct.reset();
        await this.getProductsStock();
        this.modalState(modalId, "hide");
        this.cleanProductSelectedModal();
        this.$toast.success("Operação realizada com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitUpdateProduct(modalId) {
      try {
        this.loading = true;
        const params = {
          ...this.updateProductSelected,
          acquisition_price: this.updateProductSelected.acquisition_price * 100,
          warehouse_id: this.warehouse_id,
        };
        console.log(params);
        await this.$http.post("product/stock/edit", params);
        this.$refs.modalUpdateProduct.reset();
        await this.getProductsStock();
        this.modalState(modalId, "hide");
        this.cleanUpdateProductSelected();
        this.$toast.success("Operação realizada com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    getStockProductBatch(product_id) {
      const stockProduct = this.stockProducts.filter(
        (product) => product_id == product.product_id
      );
      if (!this.batchProduct.expiration) return;
      const getStockProduct = stockProduct[0].stock.filter(
        (stockProduct) =>
          stockProduct.expiration == this.batchProduct.expiration
      );
      if (!getStockProduct.length) {
        this.hasAcquisitionPrice = false;
        this.batchProduct.acquisition_price =
          this.batchProduct.acquisition_price;
        return;
      }
      this.hasAcquisitionPrice = true;
      this.batchProduct.acquisition_price =
        getStockProduct[0].acquisition_price / 100;
    },
  },
  watch: {
    "batchProduct.expiration": function (newValue) {
      if (!this.selectedBatchProduct.product_id) return;
      this.getStockProductBatch(this.selectedBatchProduct.product_id);
    },
    selectedBatchProduct: function (bacthProduct) {
      if (!this.batchProduct.expiration) return;
      this.getStockProductBatch(bacthProduct.product_id);
    },
  },
  computed: {
    filteredStockProducts() {
      return this.stockProducts
        .map((product) => ({
          ...product,
          stock: product.stock.filter(
            (productStock) => productStock.amount > 0
          ),
        }))
        .filter((productStock) => {
          return (
            productStock.stock.length > 0 &&
            productStock.title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
          );
        });
    },
    filteredZeroAmountProducts() {
      return this.stockProducts
        .map((product) => ({
          ...product,
          stock: product.stock.filter(
            (productStock) => productStock.amount == 0
          ),
        }))
        .filter((productStock) => {
          return (
            productStock.stock.length > 0 &&
            productStock.title
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
          );
        });
    },
  },
};
</script>

<style lang="css" scoped>
.form-control[disabled] {
  background-color: #fff;
  border-color: #e8e8e8;
  color: #838383 !important;
}
.transition-animation {
  animation: refresh 600ms ease both;
}

.personalized-badge {
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

@keyframes refresh {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

.details {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

table {
  border-radius: 12px;
  background: transparent;
}

thead th {
  padding: 40px 0;
}

tbody td {
  border-color: rgba(14, 14, 20, 0.329) !important;
  cursor: pointer !important;
  padding: 0;
  width: 160px;
  max-width: 160px;
  word-wrap: break-word;
  /* white-space: pre-line; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

thead th {
  padding: 0;
  width: 160px;
  max-width: 160px;
  color: #ffffff !important;
}

tbody p span {
  font-size: 12px;
}

div.modal-footer {
  justify-content: flex-end;
}
</style>
