<template>
  <card>
    <LoadingSpinner :show="loading" />
    <router-link to="/machines">
      <base-button round icon class="my-4" type="secondary">
        <unicon name="angle-left" fill="#1d8cf8"></unicon>
      </base-button>
    </router-link>
    <div class="row col-12">
      <div class="col-12 col-md-6 container-image-machine">
        <img class="image-machine" :src="machine.image" alt="Machine Image" />
      </div>
      <div class="col-12 col-md-6 px-5 text-center text-md-left py-5 py-md-0">
        <h1 class="display-1 border-bottom pb-2">
          {{ machine.description }}
        </h1>
        <p class="display-4 font-weight-light mb-4">
          {{ machine.location.address }}, {{ machine.location.number }} -
          {{ machine.location.district }}, {{ machine.location.city }}
        </p>
        <p>Capacidade: {{ machine.capacity }}L</p>
        <p>Data de Lançamento: {{ dateFormatted(machine.launch_date) }}</p>
        <p>Modelo: {{ machine.model }}</p>
      </div>
    </div>
    <MachineStockProductsList :machineId="machine.machine_id" />
  </card>
</template>
<script>
import moment from "moment";
import MachineStockProductsList from "../components/MachineStockProductsList.vue";
import LoadingSpinner from "../components/Spinner.vue";

moment.locale("pt-BR");

export default {
  components: {
    MachineStockProductsList,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      warehouse_id: "",
      company_id: "",
      machine: {
        machine_id: "",
        launch_date: "",
        capacity: "",
        description: "",
        hardaware: "",
        image: "",
      },
    };
  },
  async mounted() {
    this.machine.machine_id = this.$route.params.id;
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getMachine();
  },
  methods: {
    dateFormatted(date) {
      return moment(date).format("L");
    },
    async getMachine() {
      try {
        this.loading = true;
        const machines = JSON.parse(localStorage.getItem("@Snackly:machines"));
        if (!machines) {
          throw Error("Máquina não encontrada");
        }
        let getMachine = machines.find(
          (machine) => machine.machine_id == this.machine.machine_id
        );
        this.machine = getMachine;
      } catch (err) {
        this.$toast.error(this.$handleErrors.message(err));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.card {
  padding: 20px !important;
}

.container-image-machine {
  text-align: center;
  background: white;
  border-radius: 12px;
  width: 600px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.container-image-machine .image-machine {
  z-index: 10;
  width: 360px;
  transition: scale 450ms ease-in;
}

.container-image-machine:hover .image-machine {
  scale: 1.2;
}
</style>
