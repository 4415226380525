<template>
  <card title="Relatório de Vendas">
    <LoadingSpinner :show="loading" />
    <div class="row align-items-end justify-content-between">
      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
        <div class="select-dropdown-container" ref="targetMachineDropdown">
          <div
            class="select-dropdown-title"
            @click="showMachine = !showMachine"
          >
            <div class="icon-container">
              <div class="title-icon"></div>
              <p>
                {{ selectedMachine.description || "Selecione uma máquina" }}
              </p>
            </div>
            <div class="title-icon">
              <unicon
                fill="#fff"
                width="16px"
                :name="showMachine ? 'angle-up' : 'angle-down'"
              ></unicon>
            </div>
          </div>
          <div
            class="select-dropdown-content"
            :style="{ display: showMachine ? 'block' : 'none' }"
          >
            <div class="option-item" @click="() => handleMachineFilter({})">
              <p>--- Selecione uma máquina --</p>
            </div>
            <div
              v-for="machine in machines"
              class="option-item"
              @click="() => handleMachineFilter(machine)"
              :key="machine.machine_id"
            >
              <p>{{ machine.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="select-dropdown-container" ref="targetDateDropdown">
          <div class="select-dropdown-title" @click="showFilter = !showFilter">
            <div class="icon-container">
              <div class="title-icon">
                <unicon fill="#fff" width="16px" name="calendar-alt"></unicon>
              </div>
              <p>{{ datesFilters[customDate] }}</p>
            </div>
            <div class="title-icon">
              <unicon
                fill="#fff"
                width="16px"
                :name="showFilter ? 'angle-up' : 'angle-down'"
              ></unicon>
            </div>
          </div>
          <div
            class="select-dropdown-content"
            :style="{ display: showFilter ? 'block' : 'none' }"
          >
            <div
              v-for="(value, key) in datesFilters"
              class="option-item"
              @click="() => handleDateFilter(key)"
              :key="key"
            >
              <p>{{ value }}</p>
            </div>
            <div class="option-item date" v-show="customDate == 9999">
              <div class="date-picker">
                <p class="date-picker-label">De:</p>
                <input type="date" v-model="startDate" />
              </div>
              <div class="date-picker">
                <p class="date-picker-label">Até:</p>
                <input type="date" v-model="endDate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center justify-content-between my-4">
      <div class="col-12 col-md-6">
        <base-input
          v-model="searchProduct"
          placeholder="Buscar produto"
        ></base-input>
      </div>
      <div
        class="col-12 col-sm-6 d-flex align-items-center justify-content-end"
      >
        <base-button class="mr-3" @click="isSort = !isSort" icon simple>
          <unicon
            :name="isSort ? 'sort-amount-up' : 'sort-amount-down'"
            fill="#1d8cf8"
          ></unicon>
        </base-button>
        <SalesReportPDF
          :endDate="endDate"
          :startDate="startDate"
          :logList="filteredAndOrderSales"
          :total="total"
        />
        <a
          class="ml-3"
          ref="RefCSVReportLink"
          @click="generateCSVReport({ type: 'csv', diff: false })"
          ><base-button type="success">Excel</base-button></a
        >
      </div>
    </div>
    <SalesReportTable
      title="Relatório de Vendas"
      :logList="filteredAndOrderSales"
    />
    <h3 class="text-right">Valor total {{ toBRL(total / 100) }}</h3>
  </card>
</template>
<script>
import moment from "moment";
import SalesReportPDF from "../components/SalesReport/SalesReportPDF.vue";
import SalesReportTable from "../components/SalesReport/SalesReportTable.vue";
import LoadingSpinner from "../components/Spinner.vue";
import TabNavigation from "../components/TabNavigation.vue";
import CSV from "../services/CSV";
import BaseButton from "../components/BaseButton.vue";
import BaseRadio from "../components/BaseRadio.vue";

moment.locale("pt-BR");

export default {
  name: "Report",
  components: {
    LoadingSpinner,
    TabNavigation,
    SalesReportTable,
    SalesReportPDF,
    BaseButton,
    BaseRadio,
  },
  data() {
    return {
      total: 0,
      showFilter: false,
      showMachine: false,
      datesFilters: {
        1: "Hoje",
        2: "Ontem",
        7: "Últimos 7 Dias",
        15: "Últimos 15 Dias",
        30: "Últimos mês",
        9999: "Intervalo",
      },
      searchProduct: "",
      isSort: false,
      startDate: "",
      endDate: "",
      selectedMachine: {},
      loading: false,
      sales: [],
      machines: [],
      products: [],
      warehouse_id: "",
      company_id: "",
      customDate: null,
      csvHeaders: [
        "Máquina",
        "Produto",
        "Código de Barras",
        "Quantidade",
        "Preço Venda",
        "Desconto",
        "Valor Total",
      ],
    };
  },
  async mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.customDate = "1";
    await this.getMachines();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const targetMachineDropdown = this.$refs.targetMachineDropdown;
      const targetDateDropdown = this.$refs.targetDateDropdown;
      if (!targetDateDropdown.contains(event.target)) {
        this.showFilter = false;
      }
      if (!targetMachineDropdown.contains(event.target)) {
        this.showMachine = false;
      }
    },
    async generateCSVReport() {
      const csvData = [];
      this.filteredAndOrderSales.forEach((item) => {
        const salesObject = {
          machine: item.machine.name,
          product: item.product.name,
          bar_code: item.product.bar_code,
          quantity: item.quantity,
          price: this.toBRL(item.price / 100),
          discount: item.discount,
          total: this.toBRL(
            ((item.price - item.discount) * item.quantity) / 100
          ),
        };
        csvData.push(salesObject);
      });
      const csvReport = new CSV(this.csvHeaders, csvData, "RELATÓRIO_ESTOQUE");
      this.$refs.RefCSVReportLink.href = csvReport.execute();
      this.$refs.RefCSVReportLink.download = csvReport.name;
    },
    resizeChartData(data, size = 10) {
      const newChartData = [];
      while (data.categories.length) {
        newChartData.push({
          categories: data.categories.splice(0, size),
          series: [
            { name: "Quantidade", data: data.series[0].data.splice(0, size) },
          ],
          unitPrices: { data: data.unitPrices.data.splice(0, size) },
        });
      }
      return newChartData;
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    async handleDateChanges() {
      if (!this.startDate || !this.endDate) return;
      await this.getSales();
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id: this.company_id,
          },
        });
        this.machines = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getSales() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.selectedMachine.machine_id,
          company_id: this.company_id,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("sales/report", {
          params,
        });
        const { sales } = response.data;
        this.sales = sales;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    handleDateFilter(data) {
      this.customDate = data;
      if (data == 9999) {
        this.startDate = "";
        this.endDate = "";
      } else {
        this.showFilter = false;
      }
    },
    handleMachineFilter(data) {
      this.selectedMachine = data;
      this.showMachine = false;
    },
  },
  computed: {
    filteredAndOrderSales() {
      let filterSales = this.sales;
      console.log("this.searchProduct.length", this.searchProduct.length);
      console.log("this.searchProduct.length", this.searchProduct);

      if (this.searchProduct.length > 0) {
        filterSales = filterSales.filter(
          (sale) =>
            sale.product.name
              .toLowerCase()
              .indexOf(this.searchProduct.toLowerCase()) != -1
        );
      }
      this.total = filterSales.reduce(
        (acc, curr) => (acc += (curr.price - curr.discount) * curr.quantity),
        0
      );
      return filterSales.sort((saleA, saleB) =>
        this.isSort
          ? new Date(saleA.date) - new Date(saleB.date)
          : new Date(saleB.date) - new Date(saleA.date)
      );
    },
  },
  watch: {
    selectedMachine() {
      this.handleDateChanges();
    },
    customDate(newValue) {
      this.endDate = moment().format("YYYY-MM-DD");
      if (newValue == 1) {
        this.startDate = moment().format("YYYY-MM-DD");
      } else if (newValue == 2) {
        this.startDate = moment().day(-1).format("YYYY-MM-DD");
      } else if (newValue == 7) {
        this.startDate = moment().subtract(7, "day").format("YYYY-MM-DD");
      } else if (newValue == 15) {
        this.startDate = moment().subtract(15, "day").format("YYYY-MM-DD");
      } else if (newValue == 30) {
        this.startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
      } else if (newValue == 12) {
        this.startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
      }
    },
    startDate() {
      this.handleDateChanges();
    },
    endDate() {
      this.handleDateChanges();
    },
  },
};
</script>
<style lang="css" >
.card {
  padding: 20px !important;
}
.select-dropdown-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.select-dropdown-title {
  cursor: pointer;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2b3553;
  border-radius: 5px;
}

.select-dropdown-title .icon-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon-container > p {
  margin: 0;
}

.select-dropdown-content {
  position: absolute;
  top: 44px;
  display: flex;
  width: 100%;
  z-index: 10;
  border-radius: 4px;
  border: 1px solid #2b3553;
  overflow: hidden;
  background: #1c2134;
}

.select-dropdown-content > .option-item {
  padding: 8px 24px;
  cursor: pointer;
  transition: background 100ms;
  background: #1c2134;
}

.select-dropdown-content > .option-item:hover {
  background: #22283e;
}

.option-item > p {
  font-size: 12px;
  font-weight: 500;
}

.option-item.date {
  padding: 16px 24px;
}

.option-item.date:hover {
  background: transparent;
}

.date-picker > .date-picker-label {
  font-size: 12px;
}
.date-picker > input {
  padding: 8px;
  margin-bottom: 4px;
  width: 100%;
  font-size: 12px;
}
</style>
