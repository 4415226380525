<template>
  <card title="Máquinas">
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Deletar Máquina</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            - Não terá mais acesso a máquina no seu gerenciador
          </div>
          <div class="modal-footer justify-content-end">
            <base-button class="mr-3" data-dismiss="modal" simple
              >Cancelar</base-button
            >
            <base-button
              data-dismiss="modal"
              @click="handleDelete()"
              type="danger"
              >Deletar</base-button
            >
          </div>
        </div>
      </div>
    </div>
    <LoadingSpinner :show="loading" />
    <div class="row my-5 align-items-center">
      <div class="col-12 col-md-6">
        <base-input
          label="Buscar Máquina"
          v-model="search"
          placeholder="Ex:. Aprimore"
        ></base-input>
      </div>
      <div class="col-12 col-md-6 text-right">
        <base-button class="mr-3" @click="refreshMachines()" link icon>
          <unicon name="sync" fill="#00f2c3" width="18px"></unicon
        ></base-button>
        <base-button @click.prevent="createMachine()" simple type="success">
          Criar máquina
        </base-button>
      </div>
    </div>
    <table class="table table-responsive-xl">
      <thead>
        <tr>
          <th scope="col">Imagem</th>
          <th class="text-center" scope="col">Endereço da Máquina</th>
          <th class="text-center" scope="col">Descrição da Máquina</th>
          <th class="text-center" scope="col">Editar</th>
          <th class="text-center" scope="col">Produtos</th>
          <th class="text-center" scope="col">Deletar</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="machine of filteredMachines"
          :key="machine.machine_id"
          class="text-center"
        >
          <td>
            <router-link :to="`/machine/${machine.machine_id}`">
              <ProductImage class="ml-0" :size="60" :src="machine.image" />
            </router-link>
          </td>
          <td>
            <router-link :to="`/machine/${machine.machine_id}`">
              <p class="text-white font-weight-bold">{{ machine.address }}</p>
            </router-link>
          </td>
          <td>
            <router-link :to="`/machine/${machine.machine_id}`"
              ><p class="text-white font-weight-bold">
                {{ machine.description }}
              </p></router-link
            >
          </td>
          <td class="text-center">
            <router-link :to="`/machine/edit/${machine.machine_id}`">
              <base-button
                link
                :disabled="!isManagerAdmin"
                :class="{ 'not-manager': !isManagerAdmin }"
              >
                <unicon name="edit" fill="#00f2c3" width="22px"></unicon>
              </base-button>
            </router-link>
          </td>
          <td class="text-center">
            <router-link :to="`/machine/${machine.machine_id}`">
              <base-button link>
                <unicon
                  name="shopping-basket"
                  fill="#1d8cf8"
                  width="22px"
                ></unicon
              ></base-button>
            </router-link>
          </td>
          <td class="text-center">
            <base-button
              :disabled="!isManagerAdmin"
              link
              data-toggle="modal"
              data-target="#exampleModal"
              @click="deleteMachineSelected = machine.machine_id"
              :class="{ 'not-manager': !isManagerAdmin }"
            >
              <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
            </base-button>
          </td>
        </tr>
      </tbody>
    </table>
  </card>
</template>
<script>
import LoadingSpinner from "../components/Spinner.vue";
import ProductImage from "../components/ProductImage.vue";
import BaseButton from "../components/BaseButton.vue";

export default {
  components: {
    LoadingSpinner,
    ProductImage,
    BaseButton,
  },
  data() {
    return {
      search: "",
      loading: false,
      machinesLocations: [],
      deleteMachineSelected: "",
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const selectedCompany =
      this.$store.getters["company/getManagerCompany"].company_id;
    if (!selectedCompany) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
    } else {
      this.getMachines();
    }
  },
  methods: {
    refreshMachines() {
      this.getMachines();
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });
        this.machinesLocations = data.machines.map((machine) => {
          const address =
            `${machine.location.address},  ${machine.location.number} -
                ${machine.location.district}, ${machine.location.city} - 
                ${machine.location.state}`.toUpperCase();
          return {
            ...machine,
            address,
            description: machine.description.toUpperCase(),
          };
        });
        localStorage.setItem(
          "@Snackly:machines",
          JSON.stringify(data.machines)
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleDelete() {
      try {
        this.loading = true;
        await this.$http.delete(`/machine/${this.deleteMachineSelected}`);
        await this.getMachines();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    createMachine() {
      this.$router.push({
        name: "createMachine",
      });
    },
    openMachine(id) {
      this.$router.push({
        name: "machine",
        params: { id },
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
  },
  computed: {
    isManagerAdmin() {
      return this.$store.getters["manager/isManagerAdmin"];
    },
    filteredMachines() {
      return this.machinesLocations.filter((machine) => {
        return (
          machine.description
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1 ||
          machine.hardware.toLowerCase().indexOf(this.search.toLowerCase()) !=
            -1
        );
      });
    },
  },
};
</script>

<style lang="css" scoped>
.not-manager {
  filter: brightness(50%);
  pointer-events: none;
}

.card {
  padding: 20px !important;
}
</style>
