<template>
  <div
    class="custom-badge"
    :style="{ background: COLORS[type || 'success'].bgColor }"
  >
    <span
      class="dot"
      :style="{ background: COLORS[type || 'success'].textColor }"
    ></span>
    <p :style="{ color: COLORS[type || 'success'].textColor }">{{ text }}</p>
  </div>
</template>

<script>
const COLORS = {
  success: {
    textColor: "#4ca872",
    bgColor: "#9dffc6",
  },
  danger: {
    textColor: "#FF6861",
    bgColor: "#fcbeb7",
  },
  info: {
    textColor: "#1d8cf8",
    bgColor: "#c9dbea",
  },
  warning: {
    textColor: "#c1a923",
    bgColor: "#fffbe4",
  },
};
export default {
  data() {
    return {
      COLORS,
    };
  },
  props: {
    text: String,
    type: {
      type: String,
      default: "info",
      description: "primary" | "success" | "danger" | "info" | "warning",
    },
  },
};
</script>

<style scoped>
.custom-badge {
  display: inline-block;
  padding: 6px 18px 6px 28px;
  border-radius: 24px;
  position: relative;
}

.custom-badge > p {
  padding: 0;
  margin: 0;
  font-weight: 550;
  font-size: 0.75rem;
}

.custom-badge > .dot {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background: #4ca872;
}
</style>