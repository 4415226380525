<template>
  <div
    class="modal fade"
    id="modalInsertProduct"
    tabindex="-1"
    aria-labelledby="modalInsertProductLabel"
    aria-hidden="true"
  >
    <LoadingSpinner :show="loading" />
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalInsertProductLabel">
            Adicionar Produto na Máquina
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="close"
            @click="cleanFields"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ValidationObserver
            ref="modalInsertProduct"
            v-slot="{ handleSubmit }"
          >
            <form
              class="row g-3 justify-content-center"
              @submit.prevent="handleSubmit(onSubmitAddProductToMachine)"
            >
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="Produto" v-slot="{ errors }">
                      <label
                        for="inputSelectedProduct"
                        class="form-label text-dark"
                        >Produto</label
                      >
                      <multiselect
                        :max-height="150"
                        :maxSelectedLabels="1"
                        id="inputSelectedProduct"
                        :showLabels="false"
                        v-model="selectedProduct"
                        :options="multiSelectStockProducts"
                        :custom-label="titleWithExpiration"
                        placeholder="Digite aqui o nome do produto que deseja adicionar"
                        label="title"
                        track-by="title"
                      >
                        <template #option="slotProps">
                          <div class="flex align-items-center text-dark">
                            <img
                              :alt="slotProps.option.title"
                              :src="slotProps.option.image"
                              class="mr-2"
                              style="width: 18px"
                            />
                            <span>{{ slotProps.option.title }} | </span>
                            <span
                              >Validade
                              <strong
                                style="
                                  display: inline-block;
                                  padding: 4px;
                                  border-radius: 4px;
                                "
                                :class="
                                  isValidDate(slotProps.option.expiration)
                                "
                                >({{
                                  dateFormatted(slotProps.option.expiration)
                                }})</strong
                              >
                            </span>
                            <span>
                              Quant. Estq
                              {{ slotProps.option.amount }}</span
                            >
                          </div>
                        </template>
                      </multiselect>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      name="Nome do Produto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputTitle" class="form-label text-dark"
                        >Nome do Produto</label
                      >
                      <input
                        :disabled="hasProductOnMachine"
                        v-model="formProduct.title"
                        name="city"
                        type="text"
                        class="form-control text-dark"
                        id="inputTitle"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-6">
                    <ValidationProvider
                      name="Descrição do produto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputDescription" class="form-label text-dark"
                        >Descrição do produto</label
                      >
                      <input
                        :disabled="hasProductOnMachine"
                        v-model="formProduct.description"
                        name="city"
                        type="text"
                        class="form-control text-dark"
                        id="inputTitle"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      name="Preço de venda"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputUnitPrice" class="form-label text-dark"
                        >Preço de venda</label
                      >
                      <money
                        class="form-control text-dark"
                        id="inputUnitPrice"
                        v-model="formProduct.price"
                        v-bind="money"
                      ></money>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-6">
                    <ValidationProvider
                      name="Desconto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputDiscount" class="form-label text-dark"
                        >Desconto</label
                      >
                      <money
                        class="form-control text-dark"
                        id="inputDiscount"
                        v-model="formProduct.discount"
                        v-bind="money"
                      ></money>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Disponibilidade"
                      :rules="
                        'required|numeric|min_value:1|max_value:' +
                        productAmountAvailability
                      "
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputAvailability"
                        class="form-label text-dark"
                        >Quantidade
                        <span class="badge badge-default"
                          >{{ formProduct.amount ? formProduct.amount : 0 }}
                          /
                          {{
                            productAmountAvailability
                              ? productAmountAvailability
                              : 0
                          }}</span
                        ></label
                      >
                      <input
                        v-model="formProduct.amount"
                        type="text"
                        class="form-control text-dark"
                        id="inputAvailability"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row py-4">
                  <div class="col-12 text-right">
                    <button
                      type="button"
                      class="btn btn-secondary mr-3"
                      data-dismiss="modal"
                      @click="cleanFields"
                    >
                      Fechar
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  maxValue,
  minValue,
  numericField,
  validationRequire,
} from "../../../middleware/validations";
import LoadingSpinner from "../../Spinner.vue";
moment.locale("pt-BR");
validationRequire();
numericField();
maxValue();
minValue();
export default {
  name: "ModalAddProductOnMachine",
  components: {
    LoadingSpinner,
    ValidationObserver,
    ValidationProvider,
    Money,
    Multiselect,
  },
  props: {
    machineId: String,
    multiSelectStockProducts: Array,
    machineProducts: Array,
  },
  data() {
    return {
      loading: false,
      company_id: null,
      warehouse_id: null,
      selectedProduct: null,
      productAmountAvailability: 0,
      hasProductOnMachine: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      formProduct: {
        product_id: "",
        image: "",
        expiration: "",
        title: "",
        description: "",
        price: 0,
        discount: 0,
        amount: 0,
      },
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
  },
  methods: {
    toBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    titleWithExpiration({ title, acquisition_price }) {
      if (!title) return "";
      return `${title} | Preço Aquisição ${this.toBRL(
        acquisition_price / 100
      )}`;
    },
    async onSubmitAddProductToMachine() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
          product_id: this.formProduct.product_id,
          expiration: this.formProduct.expiration,
          amount: Number(this.formProduct.amount),
          price: Math.round(Number(this.formProduct.price * 100)),
          description: this.formProduct.description,
          title: this.formProduct.title,
          discount: Math.round(Number(this.formProduct.discount * 100)),
          warehouse_id: this.warehouse_id,
        };
        await this.$http.post("/machine/products/create", params);
        this.$toast.success("Produto inserido com sucesso");
        this.$emit("refresh-page");
        $(`#modalInsertProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
      } catch (err) {
        $(`#modalInsertProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    cleanFields() {
      this.hasProductOnMachine = false;
      this.selectedProduct = null;
      this.formProduct.product_id = "";
      this.formProduct.image = "";
      this.formProduct.expiration = "";
      this.formProduct.title = "";
      this.formProduct.description = "";
      this.formProduct.price = 0;
      this.formProduct.discount = 0;
      this.formProduct.amount = 0;
      this.$refs.modalInsertProduct.reset();
    },
  },
  watch: {
    selectedProduct(productSelected) {
      if (productSelected) {
        const getMachineProduct = this.machineProducts.filter(
          (machineProduct) =>
            machineProduct.product_id == productSelected.product_id
        );
        this.productAmountAvailability = productSelected.amount;
        this.formProduct.product_id = productSelected.product_id;
        this.formProduct.image = productSelected.image;
        this.formProduct.expiration = productSelected.expiration;
        if (getMachineProduct.length) {
          this.hasProductOnMachine = true;
          this.formProduct.price = getMachineProduct[0].unit_price / 100;
          this.formProduct.discount = getMachineProduct[0].discount / 100;
          this.formProduct.title = getMachineProduct[0].title;
          this.formProduct.description = getMachineProduct[0].description;
        } else {
          this.hasProductOnMachine = false;
          this.formProduct.price = 0;
          this.formProduct.discount = 0;
          this.formProduct.title = "";
          this.formProduct.description = "";
        }
      } else {
        this.cleanFields();
      }
    },
  },
};
</script>

<style lang="css" >
.multiselect__element,
.multiselect__input,
.multiselect__placeholder,
.multiselect__single {
  font-size: 0.75rem;
  color: #212529;
}
.form-control[disabled] {
  background-color: #fff;
  border-color: #e8e8e8;
  color: #838383 !important;
}
</style>