<template>
  <div class="row justify-content-center align-items-center">
    <LoadingSpinner :show="loading" />

    <div class="col-12 mb-5">
      <div class="row justify-content-center">
        <div class="col-6">
          <h1 class="title text-center">Selecione uma máquina</h1>
          <form
            class="row g-3 justify-content-center"
            style="flex-direction: column"
            @submit.prevent="onSubmitSelectedMachine"
          >
            <div class="row justify-content-center">
              <div class="col-md-8 mb-3">
                <label for="selectMachine" class="form-label text-light h4"
                  >Escolha uma máquina <span class="text-danger">*</span></label
                >
                <select
                  v-model="selectedMachine"
                  class="form-control"
                  id="selectMachine"
                >
                  <option
                    class="text-light"
                    :selected="!selectedMachine"
                    :value="null"
                  >
                    --- Selecione uma máquina ---
                  </option>
                  <option
                    class="bg-light text-dark"
                    :value="machine"
                    :selected="selectedMachine"
                    v-for="machine in machinesList"
                    :key="machine.machine_id"
                  >
                    {{ machine.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-8 text-center mx-auto">
              <button type="submit" class="btn btn-primary">Salvar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "./Spinner.vue";
export default {
  name: "SelectMachineInventory",
  components: {
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      loading: false,
      selectedMachine: null,
      machinesList: [],
    };
  },
  mounted() {
    this.getMachines();
  },
  methods: {
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });
        this.machinesList = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async onSubmitSelectedMachine() {
      this.$emit("selected-machine", {
        machine_id: this.selectedMachine.machine_id,
        name: this.selectedMachine.description,
      });
    },
  },
};
</script>
<style>
</style>