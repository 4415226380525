var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"card",attrs:{"title":"Inventário da máquina"}},[_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-5 justify-content-between align-items-end"},[_c('div',[_c('p',[_vm._v("Relatório Máquina")]),_c('a',{staticClass:"badge-donwload -pdf mr-2",on:{"click":function($event){return _vm.handleGenerateReports({ type: 'pdf', diff: false })}}},[_vm._v("PDF")]),_c('a',{ref:"RefCSVReportLink",staticClass:"badge-donwload -excel",on:{"click":function($event){return _vm.handleGenerateReports({ type: 'csv', diff: false })}}},[_vm._v("Excel")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdmin()),expression:"isAdmin()"}]},[_c('p',[_vm._v("Relatório Diferença Máquina")]),_c('a',{staticClass:"badge-donwload -pdf mr-2",on:{"click":function($event){return _vm.handleGenerateReports({ type: 'pdf', diff: true })}}},[_vm._v("PDF")]),_c('a',{ref:"RefCSVReportDiffLink",staticClass:"badge-donwload -excel",on:{"click":function($event){return _vm.handleGenerateReports({ type: 'csv', diff: true })}}},[_vm._v("Excel")])]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.INVENTORY_STATUS[_vm.machineInventory.status] ==
            _vm.INVENTORY_STATUS.PROCESSED && _vm.isAdmin()},on:{"click":_vm.handleProcessInventory}},[_vm._v(" Processar inventário ")])]),_c('div',{staticClass:"row justify-content-between px-3"},[_c('router-link',{staticClass:"font-weight-bold text-primary",class:{
          disabled:
            _vm.INVENTORY_STATUS[_vm.machineInventory.status] ==
            _vm.INVENTORY_STATUS.PROCESSED,
        },attrs:{"to":("/edit/machine/inventory/" + (_vm.machineInventory.inventory_id))}},[_vm._v("Editar ")]),_c('p',[_vm._v(" "+_vm._s(_vm.machineInventory.user.name)+" | "+_vm._s(_vm.machineInventory.user.email)+" | "+_vm._s(_vm.dateFormatted(_vm.machineInventory.date))+" ")])],1),_c('StockInventoryPDF',{ref:"machineInventoryPDF",attrs:{"name":"INVENTÁRIO_MÁQUINA","stockInventory":_vm.machineInventory.products,"user":{
        name: _vm.machineInventory.user.name,
        email: _vm.machineInventory.user.email,
      },"date":_vm.machineInventory.date}}),_c('MachineInventoryDiffPDF',{ref:"machineInventoryDiffPDF",attrs:{"name":"RELATÓRIO_DIFERENÇA_MÁQUINA","items":_vm.machineInventoryDiff,"user":{
        name: _vm.machineInventory.user.name,
        email: _vm.machineInventory.user.email,
      },"date":_vm.machineInventory.date}}),_c('table',{staticClass:"table table-responsive-xl text-center"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Imagem do Produto")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Produto")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Quantidade")])])]),_c('tbody',_vm._l((_vm.machineInventory.products),function(product,index){return _c('tr',{key:index},[_c('td',[_c('ProductImage',{attrs:{"size":55,"src":product.image}})],1),_c('td',[_c('p',{staticClass:"text-white mx-2 font-weight-bold"},[_vm._v(" "+_vm._s(product.title)+" ")])]),_c('td',[_c('p',{staticClass:"text-white mx-2 font-weight-bold"},[_vm._v(" "+_vm._s(product.quantity)+" ")])])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }